import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "home text-[.1rem] font-bold" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]

import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import PageImage from '@/assets/new_image/success.png'

export default /*@__PURE__*/_defineComponent({
  __name: 'web',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const router = useRouter()

const areaList = ref([
  { x: 5.13, y: 0.22, width: .97, height: .32, href: '/detail-rule' },
  { x: 6.18, y: 0.22, width: 1.1, height: .32, href: '/activity' },
  { x: 5.08, y: 2.98, width: 1.42, height: .5, href: '/' },
])

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(PageImage),
      alt: ""
    }, null, 8, _hoisted_2),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute top-[1.15rem] left-[4.71rem] text-[.11rem] w-[2.16rem] h-[1.6rem] flex flex-col justify-center align-center text-[#fff] text-center" }, [
      _createElementVNode("p", { class: "" }, "大會稍後會以電郵"),
      _createElementVNode("p", { class: "" }, "及WHATSAPP短信通知"),
      _createElementVNode("p", null, [
        _createElementVNode("span", { class: "text-[#F8D9B8]" }, "「聖誕老人大作戰」"),
        _createTextVNode("比賽詳情")
      ])
    ], -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(areaList.value, ({ x, y, width, height, href }, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: ($event: any) => (_unref(router).push(href)),
        class: "absolute cursor-pointer",
        style: _normalizeStyle({ left: x + 'rem', top: y + 'rem', width: width + 'rem', height: height + 'rem' })
      }, null, 12, _hoisted_3))
    }), 128))
  ]))
}
}

})